<template>
	<div id="tu-dashboard">
		<div class="tu-title">
			<div class="welcome">
				<div>
					<span v-if="userInfo.active==1">Welcome back {{ userInfo.nickname }}! </span>
					<span v-else>{{ $t('dashboard.unconfirmed') }}<el-button size="small" @click="onSendResetEmail">{{ $t('dashboard.activation') }}</el-button></span>
				</div>
				<div v-if="userType == 2 && userInfo.is_live == 0">
					{{ $t('dashboard.is-offline') }}  &nbsp;&nbsp;
					<el-button type="success" size="small" @click="handleTutorLive(1)">{{ $t('dashboard.live') }}</el-button>
				</div>
				<div v-if="userType == 2 && userInfo.is_live == 1">
					{{ $t('dashboard.is-online') }}  &nbsp;&nbsp;
					<el-button type="primary" size="small" @click="handleTutorLive(0)">{{ $t('dashboard.offline') }}</el-button>
				</div>
			</div>
		</div>
		<div class="tu-content">
			<el-row :gutter="20">
				<el-col :span="12">
					<div class="shadow">
						<div class="title">{{ $t('dashboard.upcoming') }}</div>
						<div class="list">
							<el-table v-if="courseData.length > 0" :data="courseData" :show-header="false" style="width: 100%" class="tu-table" table-layout="fixed">
								<el-table-column prop="name" :label="$t('Name & Subject')" width="150">
									<template #default="scope">
										<div class="tu-nickname">{{ scope.row.user_info.nickname }}</div>
									</template>
								</el-table-column>
								<el-table-column prop="date" :label="$t('Date & Time')">
									<template #default="scope">
										<div class="tu-date">{{ handleDateShow(scope.row) }}</div>
										<div class="tu-time">{{ handleTimeShow(scope.row) }}</div>
									</template>
								</el-table-column>
								<el-table-column prop="price" :label="$t('Price & Status')" width="90">
									<template #default="scope">
										<div class="tu-price">{{ scope.row.currency }}{{ scope.row.price }}</div>
									</template>
								</el-table-column>
								<el-table-column prop="other" :label="$t('Operations')" align="center" width="90">
									<template #default="scope">
										<div class="tu-operation" @click="$router.push(`/course/${scope.row.id}`)">
											{{ $t('detail') }}
										</div>
									</template>
								</el-table-column>
							</el-table>
							<el-empty v-else :image-size="200"></el-empty>
						</div>
						<div class="footer">
							<el-button type="danger" @click="$router.push('/course')">{{ $t('dashboard.view-all-lessons') }}</el-button>
						</div>
					</div>
				</el-col>
				<el-col :span="12">
					<div class="shadow">
						<div class="title">{{ $t('dashboard.latest-messages') }}</div>
						<div class="list">
							<el-table v-if="chatData.length > 0" :data="chatData" :show-header="false" style="width: 100%" class="tu-table" table-layout="fixed">
							    <el-table-column prop="name" :label="$t('Name & Subject')" width="150">
							    	<template #default="scope">
										<div class="tu-nickname">{{ scope.row.user_info.nickname }}</div>
									</template>
							    </el-table-column>
							    <el-table-column prop="date" :label="$t('Date & Time')">
							    	<template #default="scope">
										<div class="tu-date">{{ scope.row.content }}</div>
									</template>
							    </el-table-column>
							    <el-table-column prop="price" :label="$t('Price & Status')" width="180">
							    	<template #default="scope">
										<div class="tu-price">{{ scope.row.date_time }}</div>
									</template>
							    </el-table-column>
							    <el-table-column prop="other" :label="$t('Operations')" align="center" width="90">
							    	<template #default="scope">
										<div class="tu-operation" @click="$router.push(`/chat/${scope.row.id}`)">
											{{ $t('detail') }}
										</div>
									</template>
							    </el-table-column>
							</el-table>
							<el-empty v-else :image-size="200"></el-empty>
						</div>
						<div class="footer">
							<el-button type="danger" @click="$router.push('/chat')">{{ $t('dashboard.view-all-messages') }}</el-button>
						</div>
					</div>
				</el-col>
			</el-row>
		</div>
		<div class="condition" v-if="userType==2">
			<el-divider></el-divider>
			<div style="font-size: 18px;font-weight: 600;">{{ $t('dashboard.performance') }}</div>
			<div style="padding: 10px 0;color: var(--el-color-info);margin-bottom: 15px;">{{ $t('dashboard.note') }}</div>
			<el-row :gutter="30">
				<el-col :span="6">
					<el-card shadow="always">
						<div>{{ $t('dashboard.response-time') }}</div>
						<div><i class="fa fa-clock-o"></i></div>
						<div><el-progress :percentage="50" :show-text="false" /></div>
						<div>*</div>
						<div>{{ $t('dashboard.professionally') }}</div>
					</el-card>					
				</el-col>
				<el-col :span="6">
					<el-card shadow="always">
						<div>{{ $t('dashboard.booking-score') }}</div>
						<div><i class="fa fa-book"></i></div>
						<div><el-progress :percentage="50" :show-text="false" /></div>
						<div>*/*</div>
						<div>{{ $t('dashboard.messaging') }}</div>
					</el-card>	
				</el-col>
				<el-col :span="6">
					<el-card shadow="always">
						<div>{{ $t('dashboard.repeat') }}</div>
						<div><i class="fa fa-refresh"></i></div>
						<div><el-progress :percentage="50" :show-text="false" /></div>
						<div>*/*</div>
						<div>{{ $t('dashboard.enjoy-lesson') }}</div>
					</el-card>	
				</el-col>
				<el-col :span="6">
					<el-card shadow="always">
						<div>{{ $t('dashboard.client-reviews') }}</div>
						<div><i class="fa fa-star-o"></i></div>
						<div><el-progress :percentage="50" :show-text="false" /></div>
						<div>*/*(*)</div>
						<div>{{ $t('dashboard.feedback') }}</div>
					</el-card>	
				</el-col>
			</el-row>
		</div>
		<div v-else class="st-condition">
			<div class="shadow">
				<div class="title">{{ $t('home work') }}</div>
				<div class="list">
					<el-table v-if="workData.length > 0" :data="workData" :show-header="true" style="width: 100%" class="tu-table" table-layout="fixed">
					    <el-table-column prop="name" :label="$t('course.name')">
					    	<template #default="scope">
								<div class="tu-nickname">{{ handleDateShow(scope.row.course) }}</div>
							</template>
					    </el-table-column>
					    <el-table-column prop="date" :label="$t('Name')">
					    	<template #default="scope">
							<div class="tu-date">{{ scope.row.user_info.nickname }}</div>
						</template>
					    </el-table-column>
					    <el-table-column prop="price" :label="$t('Due')" width="120">
					    	<template #default="scope">
								<div class="tu-price">{{ handleFormatShow(scope.row.expire_date) }}</div>
							</template>
					    </el-table-column>
					    <el-table-column prop="other" :label="$t('Operations')" align="center" width="90">
					    	<template #default="scope">
								<div class="tu-operation" @click="$router.push(`/course/${scope.row.course.id}`)">
									{{ $t('detail') }}
								</div>
							</template>
					    </el-table-column>
					</el-table>
					<el-empty v-else :image-size="200"></el-empty>
				</div>
				<div class="footer">
					<el-button type="danger" @click="$router.push('/work')">{{ $t('dashboard.view-all-homework') }}</el-button>
				</div>
			</div>
		</div>
	</div>
</template>
<script>
import { mapActions, mapGetters } from 'vuex'
import TimeControl from '@/utils/time-control'
import TeacherApi from '@/api/teacher'
import CommonApi from '@/api/common'
import CourseApi from '@/api/course'
import ChatApi from '@/api/chat'
import WorkApi from '@/api/work'

export default {
	name: "dashboard",
	components: {
		
	},
	data() {
		return {
			courseData: [],
			chatData: [],
			workData: []
		}
	},
	computed: {
		...mapGetters([
			'userInfo', 'userType'
		])
	},
	created() {
		// console.log("dd", this.userInfo)
		this.onCourseOrChatList();
		if(this.userType == 1) {
			this.onWorkList();
		}
	},
	methods: {
		handleTutorLive(value) {
			let params = {
				is_live: value == 1 ? 1 : 0
			}
			TeacherApi.store(params).then(res => {
				this.$message.success(this.$t('Success'));
				this.$store.dispatch('GetUserInfo');
			})
		},
		onSendResetEmail() {
			CommonApi.activation().then(res => {
				this.$message.success(this.$t('dashboard.activation-success'));
			});
		},
		onCourseOrChatList() {
			CourseApi.index({stat: 1}).then(res => {
				this.courseData = res.data;
			})
			ChatApi.index().then(res => {
				this.chatData = res.data;
			})
		},
		onWorkList() {
			WorkApi.index({stat: 1}).then(res => {
				this.workData = res.data;
			})
		},
		handleDateShow(row) {
			let book_time = row.book_time * 1000;
			return TimeControl.getFormatTime(book_time)
		},
		handleTimeShow(row) {
			let book_time = row.book_time * 1000;
			let book_end_time = (row.book_time + row.second) * 1000;
			return TimeControl.getFormatTime(book_time, "HH:mm") + ' ~ ' + TimeControl.getFormatTime(book_end_time, "HH:mm")
		},
		handleFormatShow(value) {
			return TimeControl.getFormatTime(value);
		},
	}
}
</script>
<style lang="less">
#tu-dashboard {
	.tu-title {
		background: var(--el-color-danger);
		line-height: 55px;
		color: #FFF;
		width: 100%;
		position: absolute;
		left: 0;
		right: 0;
		top: 61px;
		display: flex;
    justify-content: center;
    flex-direction: row;

		.welcome {
			width: var(--tu-main-width);
			display: flex;
			justify-content: space-between;
			align-items: center;
			flex-direction: row;

			div:first-child {
				padding-left: 20px;
			}

			div:last-child {
				padding-right: 20px;
			}
		}
	}

	.tu-content, .st-condition {
		margin-top: 60px;

		.shadow {
			box-shadow: 0 2px 12px 0 rgba(0, 0, 0, 0.1);

			.title {
				font-size: 25px;
				padding: 20px;
				background: var(--el-color-primary);
				color: #FFF;
				text-align: left;
			}

			.list {
				height: 334px;
				overflow: hidden;
				overflow-y: auto;
			}

			.footer {
				padding: 20px;
				text-align: center;
			}
		}
	}

	.condition {
		.el-row .el-col {
			.el-card__body {
				text-align: center;

				& > div:nth-child(1) {
					margin: 20px 0;
					font-weight: 600;
					font-size: 16px;
				}

				& > div:nth-child(2) {
					margin: 20px 0;
					font-size: 32px;
				}

				& > div:nth-child(3) {
					margin: 20px 0;
				}

				& > div:nth-child(4) {
					margin: 20px 0;
					color: var(--el-color-primary);
					font-weight: 600;
				}

				& > div:nth-child(5) {
					// margin: 20px 0;
					color: var(--el-color-info);
					font-weight: 500;

					display: -webkit-box;
					-webkit-line-clamp: 2;
					overflow: hidden;
					-webkit-box-orient: vertical;

					height: 38px;
				}
			}
		}
	}

	.st-condition {

	}

	.tu-table {
		.tu-nickname {
			font-size: 16px;
			font-weight: 500;
			padding: 6px 0;
		}

		.tu-subject, .tu-time, .tu-status {
			color: var(--el-color-info);
		}

		.tu-date {
			font-size: 16px;
			font-weight: 400;
			padding: 6px 0;
		}

		.tu-price {
			font-size: 16px;
			font-weight: 400;
			padding: 6px 0;
		}

		.tu-operation {
			color: var(--el-color-primary);
			font-size: 16px;
			font-weight: 400;
			padding: 6px 0;
			cursor: pointer;
		}
	}
}
</style>